import React from "react";
import CustomButton from "components/CustomButton";
import { generateToken } from "services/AuthService";
import { useNavigate } from "react-router-dom";
import { postLogin } from "services/CheckinService";
import ha_logo from "assets/static/ha_logo.png";
import SwiperCarousel from "pages/GerarEtiqueta/SwiperCarousel";
const Login = () => {
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const history = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!userName || !password) {
      return setMessage("Preencha todos os campos");
    }

    setIsLoading(true);
    // const response = { token: 'implement jwt' } //await generateToken(userName, password)
    const response = await postLogin(
      JSON.stringify({
        username: userName,
        password: password,
      })
    );

    setIsLoading(false);

    console.log("response", response);

    if (response?.ERRO) {
      return setMessage(response.ERRO);
    }
    // return;

    localStorage.setItem("token", JSON.stringify({ token: "aaaa" }));
    localStorage.setItem("user", JSON.stringify(response));

    window.location.reload();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f8f8f8",
        width: "100%",
      }}
    >
      <SwiperCarousel
        forced_style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />

      <div
        style={{
          backgroundColor: "#fff",
          padding: "1.2em",
          fontSize: "1.2em",
          borderRadius: ".5em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 2,
          flexGrow: 1,
          maxWidth: 400,
          boxShadow:
            "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <form onSubmit={handleLogin} style={{}}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={ha_logo}
              alt="Hospital de Amor Logotipo"
              style={{
                width: "12rem",
              }}
            />
          </div>

          <h3 style={{ textAlign: "center" }}>Faça Login para continuar</h3>

          <input
            type="text"
            placeholder="Usuário"
            onChange={({ target }) => setUserName(target.value)}
            style={{ marginTop: 20 }}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={({ target }) => setPassword(target.value)}
            style={{ marginTop: 20 }}
          />
          <div
            style={{
              // display: "flex",
              // justifyContent: "space-between",
              width: "100%",
              // marginTop: 20
            }}
          >
            <div style={{ fontSize: 13, marginTop: 10 }}>
              {/* <a href="#">Ou registre-se clicando aqui</a> */}
              {message && (
                <p style={{ textAlign: "center", color: "red" }}>{message}</p>
              )}
            </div>
            <CustomButton
              text="Entrar"
              style={{ width: "100%", height: 40, marginTop: 10 }}
              isLoading={isLoading}
            />
            <CustomButton
              text="Pular login"
              style={{ width: "100%", height: 40, marginTop: 10 }}
              onClick={(e) => {
                e.preventDefault();
                localStorage.setItem("user", JSON.stringify({ token: "aaaa" }));
                window.location.reload();
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
