import { CircularProgress } from "@mui/material";
import { MagnifyingGlass } from "@phosphor-icons/react";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import RadioButton from "components/RadioButton";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { getCheckinSchedule } from "services/CheckinService";

export default function GetUserSection({
  setData,
  query_type,
  setQueryType,
  setSectionIndex,
}) {
  const [query_value, setQueryValue] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    setIsLoading(true);
    const response = await getCheckinSchedule(query_value, query_type);
    setIsLoading(false);

    console.log("response", response);

    if (response?.error) {
      return setMessage("Sem resultados encontrados");
      return setMessage(response.error);
    }

    if (response.consultas.length === 0 && response.exames.length === 0) {
      return setMessage("Sem resultados encontrados");
    }

    setData(response);
    setSectionIndex(1);
  };

  return (
    <div
      className="hidethis"
      style={{
        display: "flex",
        width: "100%",
        maxWidth: "60rem",
        margin: "0 auto",
        gap: "4vh",
        flexDirection: "column",
        padding: "3vw",
      }}
    >
      {/* radio button pra selecionar qual forma de pegar o usuario vai usar */}
      <h1>Buscar o usuário</h1>
      <p
        style={{
          fontSize: "1.2rem",
        }}
      >
        Deseja usar qual dado para realizar a busca?
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <RadioButton
          label="CPF"
          value="cpf"
          checked={query_type === "cpf"}
          onChange={setQueryType}
        />

        <RadioButton
          label="Nome"
          value="nome"
          checked={query_type === "nome"}
          onChange={setQueryType}
        />

        {/* <RadioButton
          label="Data de nascimento"
          value="data-nascimento"
          checked={query_type === "data-nascimento"}
          onChange={setQueryType}
        /> */}

        <RadioButton
          label="Cod. Pessoa Física"
          value="rg"
          checked={query_type === "rg"}
          onChange={setQueryType}
        />
      </div>

      {/* campo de entrada de dados */}
      <form
        style={{
          display: "flex",
          width: "100%",
          gap: "4vh",
          justifyContent: "space-between",
        }}
        onSubmit={(e) => handleSubmit(e)}
      >
        {/* <CustomInput type="text" placeholder="Campo de busca" /> */}

        {query_type === "cpf" && (
          <ReactInputMask
            mask={"999.999.999-99"}
            defaultValue={query_value}
            onChange={(e) => {
              e.length < 15 && setQueryValue(e);
            }}
            disabled={false}
            maskChar=""
          >
            {() => <CustomInput type="text" placeholder="Insira o CPF" />}
          </ReactInputMask>
        )}

        {query_type === "nome" && (
          <CustomInput
            type="text"
            placeholder="Insira o nome completo"
            value={query_value}
            onChange={(e) => setQueryValue(e)}
          />
        )}

        {/* {query_type === "data-nascimento" && (
          <ReactInputMask
            mask={"99/99/9999"}
            defaultValue={query_value}
            onChange={(e) => {
              e.length < 11 && setQueryValue(e);
            }}
            disabled={false}
            maskChar=""
          >
            {() => (
              <CustomInput
                type="text"
                placeholder="Insira a data de nascimento"
              />
            )}
          </ReactInputMask>
        )} */}

        {query_type === "rg" && (
          <CustomInput
            type="text"
            placeholder="Insira o código de pessoa física"
            value={query_value}
            onChange={(e) => setQueryValue(e)}
          />
        )}

        <CustomButton
          size={120}
          icon={!isLoading && <MagnifyingGlass size={26} />}
          text={
            isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Buscar"
            )
          }
        />
      </form>

      <div>
        <p>{message}</p>
      </div>

      {/* botao pra fazer a requisicao */}
    </div>
  );
}
