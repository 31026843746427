import Checkbox from "components/Checkbox";
import RadioButton from "components/RadioButton";
import { useEffect } from "react";

export default function DadosAnamneseSection({
  mamografiaAnswers,
  setMamografiaAnswers,
}) {
  useEffect(() => {
    console.log(mamografiaAnswers);
  }, [mamografiaAnswers]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <QuestionBody>
        <QuestionHeader index={1} text="Tem nódulo ou caroço na mama?" />
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <Checkbox
            label="Sim, mama direita"
            name="nodulo_caroco_mama"
            checked={
              mamografiaAnswers.DadosAnamneseSection.nodulo_caroco_mama
                .mama_direita
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.nodulo_caroco_mama.mama_direita = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="Sim, mama esquerda"
            name="nodulo_caroco_mama"
            checked={
              mamografiaAnswers.DadosAnamneseSection.nodulo_caroco_mama
                .mama_esquerda
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.nodulo_caroco_mama.mama_esquerda = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="Não"
            name="nodulo_caroco_mama"
            checked={
              mamografiaAnswers.DadosAnamneseSection.nodulo_caroco_mama.nao
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.nodulo_caroco_mama.nao = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
      </QuestionBody>

      <QuestionBody>
        <QuestionHeader
          index={2}
          text="Apresenta risco elevado para câncer de mama?"
        />
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <RadioButton
            label="Sim"
            value="Sim"
            name="risco_elevado_cancer_mama"
            checked={
              mamografiaAnswers.DadosAnamneseSection
                .risco_elevado_cancer_mama === "Sim"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.risco_elevado_cancer_mama = e;
              setMamografiaAnswers(temp);
            }}
          />
          <RadioButton
            label="Não"
            value="Não"
            name="risco_elevado_cancer_mama"
            checked={
              mamografiaAnswers.DadosAnamneseSection
                .risco_elevado_cancer_mama === "Não"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.risco_elevado_cancer_mama = e;
              setMamografiaAnswers(temp);
            }}
          />
          <RadioButton
            label="Não sabe"
            value="Não sabe"
            name="risco_elevado_cancer_mama"
            checked={
              mamografiaAnswers.DadosAnamneseSection
                .risco_elevado_cancer_mama === "Não sabe"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.risco_elevado_cancer_mama = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
      </QuestionBody>

      <QuestionBody>
        <QuestionHeader
          index={3}
          text="Antes desta consulta, teve suas mamas examinadas por um profissional de saúde?"
        />
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <RadioButton
            label="Sim"
            value="Sim"
            name="mamas_examinadas_profissional_saude"
            checked={
              mamografiaAnswers.DadosAnamneseSection
                .mamas_examinadas_profissional_saude === "Sim"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.mamas_examinadas_profissional_saude = e;
              setMamografiaAnswers(temp);
            }}
          />
          <RadioButton
            label="Nunca foram examinadas anteriormente"
            value="Nunca foram examinadas anteriormente"
            name="mamas_examinadas_profissional_saude"
            checked={
              mamografiaAnswers.DadosAnamneseSection
                .mamas_examinadas_profissional_saude ===
              "Nunca foram examinadas anteriormente"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.mamas_examinadas_profissional_saude = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
      </QuestionBody>

      <QuestionBody>
        <QuestionHeader index={4} text="Fez mamografia alguma vez?" />
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <div
            style={{
              display: "flex",
              gap: "0.6rem",
            }}
          >
            <RadioButton
              label="Sim"
              value="Sim"
              name="fez_mamografia_alguma_vez"
              checked={
                mamografiaAnswers.DadosAnamneseSection.fez_mamografia_alguma_vez
                  .answer === "Sim"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseSection.fez_mamografia_alguma_vez.answer = e;
                setMamografiaAnswers(temp);
              }}
            />
            <input
              type="number"
              placeholder="Ano"
              style={{
                width: "7rem",
                display:
                  mamografiaAnswers.DadosAnamneseSection
                    .fez_mamografia_alguma_vez.answer === "Sim"
                    ? "block"
                    : "none",
              }}
              value={
                mamografiaAnswers.DadosAnamneseSection.fez_mamografia_alguma_vez
                  .ano
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseSection.fez_mamografia_alguma_vez.ano =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
          <RadioButton
            label="Não"
            value="Não"
            name="fez_mamografia_alguma_vez"
            checked={
              mamografiaAnswers.DadosAnamneseSection.fez_mamografia_alguma_vez
                .answer === "Não"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.fez_mamografia_alguma_vez.answer = e;
              setMamografiaAnswers(temp);
            }}
          />
          <RadioButton
            label="Não sabe"
            value="Não sabe"
            name="fez_mamografia_alguma_vez"
            checked={
              mamografiaAnswers.DadosAnamneseSection.fez_mamografia_alguma_vez
                .answer === "Não sabe"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseSection.fez_mamografia_alguma_vez.answer = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
      </QuestionBody>
    </div>
  );
}

function QuestionBody({ children }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontSize: "1.4rem",
        gap: "0.4rem",
      }}
    >
      {children}
    </div>
  );
}

function QuestionHeader({ index, text }) {
  return (
    <span
      style={{
        fontWeight: "bold",
      }}
    >
      <span style={{ opacity: 0.4 }}>{index}.</span> {text}
    </span>
  );
}
