import Checkbox from "components/Checkbox";
import RadioButton from "components/RadioButton";
import ReactInputMask from "react-input-mask";

export default function DadosAnamneseUnidadeRadiologicaSection({
  mamografiaAnswers,
  setMamografiaAnswers,
}) {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6rem",
          width: "max-content",
        }}
      >
        <QuestionBody>
          <QuestionHeader index={7} text="Histórico menstrual" />
          <div style={{ display: "flex", gap: "0.6rem" }}>
            <h4
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Última menstruação
            </h4>
            <input
              type="date"
              style={{
                display: mamografiaAnswers
                  .DadosAnamneseUnidadeRadiologicaSection.historico_menstrual
                  .ultima_menstruacao.nao_lembra
                  ? "none"
                  : "block",
              }}
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .historico_menstrual.ultima_menstruacao.data
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.historico_menstrual.ultima_menstruacao.data =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
            <Checkbox
              label="Não lembra"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .historico_menstrual.ultima_menstruacao.nao_lembra
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.historico_menstrual.ultima_menstruacao.nao_lembra =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "0.6rem" }}>
            <h4>Menopausa</h4>
            <input
              type="number"
              placeholder="Anos"
              style={{
                display: mamografiaAnswers
                  .DadosAnamneseUnidadeRadiologicaSection.historico_menstrual
                  .menopausa.nao_lembra
                  ? "none"
                  : "block",
              }}
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .historico_menstrual.menopausa.anos
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.historico_menstrual.menopausa.anos =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
            <Checkbox
              label="Não lembra"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .historico_menstrual.menopausa.nao_lembra
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.historico_menstrual.menopausa.nao_lembra =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
          <Checkbox
            label="Nunca menstruou"
            checked={
              mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                .historico_menstrual.nunca_menstruou
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.DadosAnamneseUnidadeRadiologicaSection.historico_menstrual.nunca_menstruou =
                e;
              setMamografiaAnswers(temp);
            }}
          />
        </QuestionBody>

        <QuestionBody>
          <QuestionHeader
            index={8}
            text="Usa hormônio/remédio para tratar menopausa?"
          />
          <div style={{ display: "flex", gap: "0.6rem" }}>
            <RadioButton
              label="Sim"
              value="Sim"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .usa_hormonio_remedio_tratar_menopausa === "Sim"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.usa_hormonio_remedio_tratar_menopausa =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
            <RadioButton
              label="Não"
              value="Não"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .usa_hormonio_remedio_tratar_menopausa === "Não"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.usa_hormonio_remedio_tratar_menopausa =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
            <RadioButton
              label="Não sabe"
              value="Não sabe"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .usa_hormonio_remedio_tratar_menopausa === "Não sabe"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.usa_hormonio_remedio_tratar_menopausa =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
        </QuestionBody>

        <QuestionBody>
          <QuestionHeader index={8} text="Você está grávida?" />
          <div style={{ display: "flex", gap: "0.6rem" }}>
            <RadioButton
              label="Sim"
              value="Sim"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .esta_gravida === "Sim"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.esta_gravida = e;
                setMamografiaAnswers(temp);
              }}
            />
            <RadioButton
              label="Não"
              value="Não"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .esta_gravida === "Não"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.esta_gravida = e;
                setMamografiaAnswers(temp);
              }}
            />
            <RadioButton
              label="Não sabe"
              value="Não sabe"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .esta_gravida === "Não sabe"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.esta_gravida = e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
        </QuestionBody>

        <QuestionBody>
          <QuestionHeader
            index={8}
            text="Fez radioterapia de mama? Em que ano?"
          />
          <div style={{ display: "flex", gap: "0.6rem" }}>
            <div
              style={{
                display: "flex",
                gap: "0.6rem",
              }}
            >
              <RadioButton
                label="Sim"
                value="Sim"
                checked={
                  mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                    .fez_radioterapia.answer === "Sim"
                }
                onChange={(e) => {
                  let temp = { ...mamografiaAnswers };
                  temp.DadosAnamneseUnidadeRadiologicaSection.fez_radioterapia.answer =
                    e;
                  setMamografiaAnswers(temp);
                }}
              />
              <input
                type="number"
                placeholder="Ano"
                style={{
                  width: "7rem",
                  display:
                    mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                      .fez_radioterapia.answer === "Sim"
                      ? "block"
                      : "none",
                }}
                value={
                  mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                    .fez_radioterapia.ano
                }
                onChange={(e) => {
                  let temp = { ...mamografiaAnswers };
                  temp.DadosAnamneseUnidadeRadiologicaSection.fez_radioterapia.ano =
                    e.target.value;
                  setMamografiaAnswers(temp);
                }}
              />
            </div>
            <RadioButton
              label="Não"
              value="Não"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_radioterapia.answer === "Não"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_radioterapia.answer =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
            <RadioButton
              label="Não sabe"
              value="Não sabe"
              checked={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_radioterapia.answer === "Não sabe"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_radioterapia.answer =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
        </QuestionBody>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6rem",
        }}
      >
        <QuestionBody>
          <QuestionHeader index={8} text="Fez cirurgia de mama? Em que ano?" />
          <RenderTableCirurgiaMama
            {...{
              mamografiaAnswers,
              setMamografiaAnswers,
            }}
          />
        </QuestionBody>
      </div>
    </div>
  );
}

function RenderTableCirurgiaMama({ mamografiaAnswers, setMamografiaAnswers }) {
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Direita</th>
          <th>Esquerda</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <h3>Tumorectomia</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.tumorectomia.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.tumorectomia.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.tumorectomia.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.tumorectomia.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Segmentectomia</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.segmentectomia.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.segmentectomia.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.segmentectomia.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.segmentectomia.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Dutectomia</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.dutectomia.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.dutectomia.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.dutectomia.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.dutectomia.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Mastectomia</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.mastectomia.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.mastectomia.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.mastectomia.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.mastectomia.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Mastectomia poup. pele</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.mastectomia_poup_pele.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.mastectomia_poup_pele.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.mastectomia_poup_pele.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.mastectomia_poup_pele.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Linfadenectomia axilar</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.linfadenectomia_axilar.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.linfadenectomia_axilar.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.linfadenectomia_axilar.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.linfadenectomia_axilar.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Biopsia linfonodo sentinela</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.biopsia_linfonodo_sentinela.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.biopsia_linfonodo_sentinela.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.biopsia_linfonodo_sentinela.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.biopsia_linfonodo_sentinela.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Reconstrução mamária</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.reconstrucao_mamaria.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.reconstrucao_mamaria.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.reconstrucao_mamaria.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.reconstrucao_mamaria.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Plástica redutora</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.plastica_redutora.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.plastica_redutora.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.plastica_redutora.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.plastica_redutora.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Plástica com implantes</h3>
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.plastica_com_implantes.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.plastica_com_implantes.direita =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="number"
              placeholder="Ano"
              value={
                mamografiaAnswers.DadosAnamneseUnidadeRadiologicaSection
                  .fez_cirurgia_de_mama.plastica_com_implantes.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.DadosAnamneseUnidadeRadiologicaSection.fez_cirurgia_de_mama.plastica_com_implantes.esquerda =
                  e.target.value;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function QuestionBody({ children }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "0.4rem",
      }}
    >
      {children}
    </div>
  );
}

function QuestionHeader({ index, text }) {
  return (
    <span
      style={{
        fontWeight: "bold",
        fontSize: "1.4rem",
      }}
    >
      <span style={{ opacity: 0.4 }}>{index}.</span> {text}
    </span>
  );
}
