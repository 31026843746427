import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RadioButton from "components/RadioButton";
import CustomButton from "components/CustomButton";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import DadosAnamneseSection from "./sections/DadosAnamnese";
import IndicacaoClinicaSection from "./sections/IndicacaoClinica";
import DadosAnamneseUnidadeRadiologicaSection from "./sections/DadosAnamneseUnidadeRadiologica";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function QuestionsMamografiaSection({
  mamografiaAnswers,
  setMamografiaAnswers,
  setSectionIndex,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        gap: "2vh",
        flexDirection: "column",
        padding: "3vw",
        overflowY: "scroll",
        paddingTop: 0,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Dados da anamnese" {...a11yProps(0)} />
          <Tab label="Indicação clínica" {...a11yProps(1)} />
          <Tab
            label="Dados da anamnese unidade radiológica"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DadosAnamneseSection
          {...{ mamografiaAnswers, setMamografiaAnswers }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <IndicacaoClinicaSection
          {...{
            mamografiaAnswers,
            setMamografiaAnswers,
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <DadosAnamneseUnidadeRadiologicaSection
          {...{
            mamografiaAnswers,
            setMamografiaAnswers,
          }}
        />
      </CustomTabPanel>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomButton
          text={"Anterior"}
          icon={<ArrowLeft size={26} />}
          disabled={value === 0}
          onClick={() => {
            setValue(value - 1);
          }}
        />
        <CustomButton
          text={value === 2 ? "Finalizar" : "Próximo"}
          icon={<ArrowRight size={26} />}
          onClick={() => {
            value === 2 ? setSectionIndex(3) : setValue(value + 1);
          }}
        />
      </div>
    </div>
  );
}
