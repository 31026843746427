import { useState } from "react";
/**
 *
 * @param {string} type
 * @param {string} placeholder
 * @param {string} value
 * @param {function} onChangeTarget
 * @param {Icon} icon
 * @param {boolean} reverse
 * @param {boolean} required
 * @param {boolean} disabled
 * @param {string} helperText
 * @param {function} onChange
 * @param {function} onClick
 * @param {object} inputProps
 * @param {object} inputStyle
 * @param {object} parentStyle
 *
 */

const CustomInput = ({
  type = "text",
  placeholder = "",
  value,
  onChangeTarget = null,
  icon = null,
  reverse = null,
  required = false,
  disabled = false,
  helperText = null,
  onChange = () => {},
  onClick = () => {},
  inputProps = {},
  inputStyle = {},
  parentStyle = {},
  params = {},
}) => {
  const [helper_text_hover, set_helper_text_hover] = useState(false);

  return (
    <input
      type={"text"}
      placeholder={placeholder}
      value={value}
      onChange={
        onChangeTarget
          ? (e) => onChangeTarget(e)
          : (e) => onChange(e.target.value)
      }
      style={{
        paddingLeft: icon && !reverse ? "3.2rem" : "1rem",
        paddingRight: icon && reverse ? "3.2rem" : "1rem",
        ...inputStyle,
      }}
      disabled={disabled}
      {...inputProps}
      {...params}
    />
  );
};

export default CustomInput;
