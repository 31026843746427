import GerarEtiquetaPage from "pages/GerarEtiqueta";
import Login from "pages/Login";
import { Route, Routes } from "react-router-dom";

const RoutesContainer = () => {

  const getRoutes = () => {
    const token = localStorage.getItem('user')

    return token
      ? privateRoutes()
      : publicRoutes()
  }

  const publicRoutes = () => (
    <Routes>
      <Route path="*" element={<Login />} />
    </Routes>
  )

  const privateRoutes = () => (
    <Routes>
      <Route path="*" element={<GerarEtiquetaPage />} />
    </Routes>
  )

  return (
    getRoutes()
  );
};

export default RoutesContainer;
