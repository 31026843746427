import tag_success from "assets/static/tag_success.svg";
import CustomButton from "components/CustomButton";
import { getFile } from "services/CheckinService";
import { API_URL } from "utilities/variables";
import SelectUserSection from "../SelectUser";

export default function GenerateTag(props) {
  console.log(props)
  return (
    <>
    <div
    className="d-block-print"
      style={{
        display: "flex",
        width: "100%",
        gap: "4vh",
        flexDirection: "column",
        padding: "3vw",
        alignItems: "center",
      }}
    >
      <span
      className="hidethis"
        style={{
          fontSize: "1.8rem",
          fontWeight: "bold",
        }}
      >
        Pronto!
      </span>
      <img
        src={'http://10.0.100.1:3001/api/'+`file/${props.ticketFile.ticketFile}`}
        alt="Sucesso"
        className='img_print'
        id="ticket"
        style={{
          // maxHeight: "22rem",
        }}
      />
      <div style={{display:'flex',justifyContent:'center'}} className="hidethis">
      <CustomButton
          text='Imprimir'
          style={{height:40,marginTop:10,minWidth:'none',marginRight:10}}
          // isLoading={isLoading}
          className="hidethis"
          onClick={(e)=>{
            window.print();
          }}
        />
        <CustomButton
          text='Novo Atendimento'
          style={{height:40,marginTop:10,minWidth:'none',marginRight:10}}
          // isLoading={isLoading}
          className="hidethis"
          onClick={(e)=>{
            props.clearSections();
          }}
        />
      </div>
      
       
      
    </div>
    <div className="hidethis">
      <div
          className="hidethis"
            style={{
              // height: "100vh",
              // overflow: "hidden",
              width: "100%",
              padding: "2vh 0",
              overflowX:'scroll'
            }}
          >
        <p  style={{fontSize:14,marginBottom:15,textAlign:'center'}}>Outros horários de <b>{props.selected_user?.nm_paciente}</b>:</p>
        <SelectUserSection
          end
              {...{
                data:props.othersAppoitments,
                selected_user:props.selected_user,
                setSelectedUser:props.setSelectedUser,
                setSectionIndex:props.setSectionIndex,
              }}
            />
      </div>

      </div>
    </>
  );
}
