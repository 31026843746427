import React from "react";
import styles from "./style.module.css";

export default function SelectUserSection({
  data,
  selected_user,
  setSelectedUser,
  setSectionIndex,
  end
}) {
  // const users = [
  //   {
  //     name: "João da Silva Sauro",
  //     cpf: "123.456.789-00",
  //     birth_date: "01/01/2000",
  //   },
  //   {
  //     name: "João da Silva Sauro",
  //     cpf: "123.456.789-00",
  //     birth_date: "01/01/2000",
  //   },
  //   {
  //     name: "João da Silva Sauro",
  //     cpf: "123.456.789-00",
  //     birth_date: "01/01/2000",
  //   },
  //   {
  //     name: "João da Silva Sauro",
  //     cpf: "123.456.789-00",
  //     birth_date: "01/01/2000",
  //   },
  //   {
  //     name: "João da Silva Sauro",
  //     cpf: "123.456.789-00",
  //     birth_date: "01/01/2000",
  //   },
  //   {
  //     name: "João da Silva Sauro",
  //     cpf: "123.456.789-00",
  //     birth_date: "01/01/2000",
  //   },
  //   {
  //     name: "João da Silva Sauro",
  //     cpf: "123.456.789-00",
  //     birth_date: "01/01/2000",
  //   },
  // ];

  const [tableType, setTableType] = React.useState(0)

  React.useEffect(() => data.consultas >= data.exames
    ? setTableType(0)
    : setTableType(1)
    , [])

  const renderMap = (usersArray) => usersArray.map((user) => (
    <tr
      key={user.nr_cpf}
      onClick={async () => {
        setSelectedUser(user);
        setSectionIndex(end==undefined? 2:3);
      }}
    >
      {!tableType ? <>
      <td>{user.nm_paciente}</td>
      <td>{user.nr_cpf}</td>
      <td>{user.dt_nascimento}</td>
      <td>{user.nm_medico ?? '-'}</td>
      <td>{user.ds_especialidade ?? '-'}</td>
      <td>{user.dt_agenda ?? '-'}</td></>:
      <>
      <td>{user.nm_paciente}</td>
      <td>{user.nr_cpf}</td>
      <td>{user.dt_nascimento}</td>
      <td>{user.nm_agenda ?? '-'}</td>
      <td>{user.dt_agenda ?? '-'}</td>
      </>
      }

    </tr>
  ))
  // .filter((item)=>!selected_user?true:item.nr_seq_agenda!=selected_user.nr_seq_agenda)

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        gap: "4vh",
        flexDirection: "column",
        padding: "0px 3vw 0vw",
        // overflowY: "scroll",
        paddingTop: 0,
        paddingBottom:20
      }}
    >
      <div style={{display:'flex'}}>
        <input style={{width:16}} type="radio" id="appointment" onClick={() => setTableType(0)} checked={!tableType} />
        <label style={{marginLeft:10}} htmlFor="appointment">Mostrar consultas</label>
      </div>
      <div style={{display:'flex'}}>
        <input style={{width:16}} type="radio" id="exam" onClick={() => setTableType(1)} checked={tableType} />
        <label style={{marginLeft:10}} htmlFor="exam">Mostrar exames</label>
      </div>

      <table className={styles.customTable}>
        <thead>
          <tr>
            {!tableType ? <>
            <th>Nome</th>
            <th>CPF</th>
            <th>Data de nascimento</th>
            <th>Médico</th>
            <th>Especialidade</th>

            <th>Data do agendamento</th>
            </>: <>
            <th>Nome</th>
            <th>CPF</th>
            <th>Data de nascimento</th>
            <th>Tipo</th>
            <th>Data do agendamento</th>
            </>}

          </tr>
        </thead>
        <tbody>
          {renderMap(!tableType ? data.consultas.filter((item)=>!selected_user?true:item.nr_seq_agenda!=selected_user.nr_seq_agenda) : data.exames.filter((item)=>!selected_user?true:item.nr_seq_agenda!=selected_user.nr_seq_agenda))}
        </tbody>
      </table>
      {((!tableType && data.consultas.filter((item)=>!selected_user?true:item.nr_seq_agenda!=selected_user.nr_seq_agenda).length==0)) ? <div >
      <p style={{textAlign:'center'}}>Nenhum agendamento encontrado</p>
      </div>:''}

      {((tableType && data.exames.filter((item)=>!selected_user?true:item.nr_seq_agenda!=selected_user.nr_seq_agenda).length==0)) ? <div >
      <p style={{textAlign:'center'}}>Nenhum agendamento encontrado</p>
      </div>:''}
      
    </div>
  );
}
