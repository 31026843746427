import React from 'react'
import { CircularProgress } from "@mui/material";

export default function CustomButton({
  text,
  size,
  height,
  disabled = false,
  onClick = () => { },
  icon = null,
  isLoading = false,
  style={}
}) {
  const [buttonWidth, setButtonWidth] = React.useState(size)
  const [buttonHeight, setButtonHeight] = React.useState(height)

  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    if (!buttonWidth) setButtonWidth(buttonRef.current.clientWidth)
    if (!buttonHeight) setButtonHeight(buttonRef.current.clientHeight)
  }, [buttonRef.current])

  return (
    <button
      className='hidethis'
      style={{
        display: "flex",
        gap: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.2rem",
        backgroundColor: "#0b64c2",
        minWidth: buttonWidth ?? "fit-content",
        height: buttonHeight ?? "auto",
        padding: "0.5rem 2rem",
        paddingLeft: "1.6rem",
        borderRadius: "10rem",
        color: "white",
        border: "none",
        cursor: "pointer",
        ...style
      }}
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading
        ? <><CircularProgress size={20} color="inherit" /> <span>Aguarde...</span></>
        :
        <>
          {icon}
          {text}
        </>}
    </button>
  );
}
