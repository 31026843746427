import { API_URL } from "./variables";

export const get = async (path,url=API_URL) => {
    const token = localStorage.getItem("token");
    const headers = {
        "Accept": "application/json",
        ...(token && { "Authorization": "Bearer " + token })
    };

    let httpCode
    let response = await fetch(url + path, {
        method: "GET",
        headers: headers
    })
        .then(async (res) => {
            httpCode = res.status
            return await res.json()
        })
        .catch(err => console.log("Error to get: " + err.message));

    if (parseInt(httpCode.toString()[0]) !== 2) {
        return { error: response?.message || 'Falha ao resgatar dados' }
    }

    return response;
}

export const post = async (path, body,url=API_URL) => {
    const token = localStorage.getItem("token");
    const headers = {
        "Accept": "application/json",
        ...(!(body instanceof FormData) && { "Content-type": "application/json" }),
        ...(token && { "Authorization": "Bearer " + token })
    };

    let httpCode;
    let response = await fetch(url + path, {
        method: "POST",
        headers: headers,
        body: body
    })
        .then(async (res) => {
            httpCode = res.status;
            return await res.json();
        })
        .catch(err => console.log("Error to post: " + err.message));

    if (parseInt(httpCode.toString()[0]) !== 2) {
        return { error: response?.message || 'Falha ao resgatar dados' }
    }

    return response;
}

export const put = async (path, body) => {
    const headers = {
        "Accept": "application/json",
        ...(!(body instanceof FormData) && { "Content-type": "application/json" })
    }

    let response = await fetch(path, {
        method: "PUT",
        headers: headers,
        body: body
    })
        .then(async (res) => await res.json())
        .catch(err => console.log("Error to put: " + err.message));

    return response;
}

export const patch = async (path, body) => {
    const headers = {
        "Accept": "application/json",
        ...(!(body instanceof FormData) && { "Content-type": "application/json" })
    }

    let response = await fetch(path, {
        method: "PATCH",
        headers: headers,
        body: body
    })
        .then(async (res) => await res.json())
        .catch(err => console.log("Error to patch: " + err.message));

    return response;
}

export const useDelete = async (path, body) => {
    const headers = {
        "Accept": "application/json",
        "Content-type": "application/json"
    }

    let response = await fetch(path, {
        method: "DELETE",
        headers: headers,
        body: body
    })
        .then(async (res) => await res.json())
        .catch(err => console.log("Error to delete: " + err.message));

    return response;
}