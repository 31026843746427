import Checkbox from "components/Checkbox";
import RadioButton from "components/RadioButton";

export default function IndicacaoClinicaSection({
  mamografiaAnswers,
  setMamografiaAnswers,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.6rem",
      }}
    >
      <QuestionBody>
        <QuestionHeader index={5} text="Mamografia diagnóstica" />
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <RadioButton
            label="Mama direita"
            value="Mama direita"
            name="mama_diagnostica"
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.mama_diagnostica ===
              "Mama direita"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.mama_diagnostica = e;
              setMamografiaAnswers(temp);
            }}
          />
          <RadioButton
            label="Mama esquerda"
            value="Mama esquerda"
            name="mama_diagnostica"
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.mama_diagnostica ===
              "Mama esquerda"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.mama_diagnostica = e;
              setMamografiaAnswers(temp);
            }}
          />
          <RadioButton
            label="Ambas"
            value="Ambas"
            name="mama_diagnostica"
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.mama_diagnostica ===
              "Ambas"
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.mama_diagnostica = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
      </QuestionBody>

      {/* 5a */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex" }}>
          <Checkbox
            label={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                <span style={{ opacity: 0.8 }}>5a.</span> Achados no exame
                clínico
              </span>
            }
            name="achados_exame_clinico"
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico
                .show
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico.show = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
        <div
          style={{
            display: mamografiaAnswers.IndicacaoClinicaSection
              .achados_exame_clinico.show
              ? "flex"
              : "none",
            gap: "1rem",
          }}
        >
          <RenderAchadosNoExameClinico
            lado_da_mama="direita"
            {...{
              mamografiaAnswers,
              setMamografiaAnswers,
            }}
          />
          <RenderAchadosNoExameClinico
            lado_da_mama="esquerda"
            {...{
              mamografiaAnswers,
              setMamografiaAnswers,
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        {/* 5b */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <Checkbox
              label={
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ opacity: 0.8 }}>5b.</span> Controle radiológico
                  Categoria 3
                </span>
              }
              name="controle_radiologico_categoria_3"
              checked={
                mamografiaAnswers.IndicacaoClinicaSection
                  .controle_radiologico_categoria_3.show
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection.controle_radiologico_categoria_3.show =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
          <div
            style={{
              display: mamografiaAnswers.IndicacaoClinicaSection
                .controle_radiologico_categoria_3.show
                ? "flex"
                : "none",
              gap: "1rem",
            }}
          >
            {/* 5b */}
            <RenderTableMamaDirecao
              form_id="controle_radiologico_categoria_3"
              {...{
                mamografiaAnswers,
                setMamografiaAnswers,
              }}
            />
          </div>
        </div>
        {/* 5c */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <Checkbox
              label={
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ opacity: 0.8 }}>5c.</span> Lesão com
                  diagnóstico de câncer
                </span>
              }
              name="lesao_diagnostico_cancer"
              checked={
                mamografiaAnswers.IndicacaoClinicaSection
                  .lesao_diagnostico_cancer.show
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection.lesao_diagnostico_cancer.show = e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
          <div
            style={{
              display: mamografiaAnswers.IndicacaoClinicaSection
                .lesao_diagnostico_cancer.show
                ? "flex"
                : "none",
              gap: "1rem",
            }}
          >
            {/* 5c */}
            <RenderTableMamaDirecao
              form_id="lesao_diagnostico_cancer"
              {...{
                mamografiaAnswers,
                setMamografiaAnswers,
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.6rem",
          }}
        >
          {/* 5d */}
          <div style={{ display: "flex" }}>
            <Checkbox
              label={
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ opacity: 0.8 }}>5d.</span> Avaliação da
                  resposta de QT neo-adjuvante
                </span>
              }
              name="avaliacao_resposta_qt_neo_adjuvante"
              checked={
                mamografiaAnswers.IndicacaoClinicaSection
                  .avaliacao_resposta_qt_neo_adjuvante
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection.avaliacao_resposta_qt_neo_adjuvante =
                  e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>

          {/* 6 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex" }}>
              <Checkbox
                label={
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <span style={{ opacity: 0.8 }}>6.</span> Mamografia
                    rastreamento
                  </span>
                }
                name="mamografia_rastreamento"
                checked={
                  mamografiaAnswers.IndicacaoClinicaSection
                    .mamografia_rastreamento.show
                }
                onChange={(e) => {
                  let temp = { ...mamografiaAnswers };
                  temp.IndicacaoClinicaSection.mamografia_rastreamento.show = e;
                  setMamografiaAnswers(temp);
                }}
              />
            </div>

            <div
              style={{
                display: mamografiaAnswers.IndicacaoClinicaSection
                  .mamografia_rastreamento.show
                  ? "flex"
                  : "none",
                width: "fit-content",
                marginTop: "0.6rem",
                alignItems: "center",
                gap: "0.6rem",
              }}
            >
              <h3
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Data de solicitação
              </h3>
              <input
                type="date"
                name="data_solicitacao_mamografia_rastreamento"
                value={
                  mamografiaAnswers.IndicacaoClinicaSection
                    .mamografia_rastreamento.data_solicitacao
                }
                onChange={(e) => {
                  let temp = { ...mamografiaAnswers };
                  temp.IndicacaoClinicaSection.mamografia_rastreamento.data_solicitacao =
                    e.target.value;
                  setMamografiaAnswers(temp);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RenderTableMamaDirecao({
  form_id,
  mamografiaAnswers,
  setMamografiaAnswers,
}) {
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Direita</th>
          <th>Esquerda</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <h3>Nódulo</h3>
          </td>
          <td>
            <input
              type="checkbox"
              name={`nodulo_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id].nodulo
                  .direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[form_id].nodulo.direita =
                  e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="checkbox"
              name={`nodulo_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id].nodulo
                  .esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[form_id].nodulo.esquerda =
                  e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Microcalcificações</h3>
          </td>
          <td>
            <input
              type="checkbox"
              name={`microcalcificacoes_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id]
                  .microcalcificacoes.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[
                  form_id
                ].microcalcificacoes.direita = e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="checkbox"
              name={`microcalcificacoes_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id]
                  .microcalcificacoes.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[
                  form_id
                ].microcalcificacoes.esquerda = e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Assimetria focal</h3>
          </td>
          <td>
            <input
              type="checkbox"
              name={`assimetria_focal_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id]
                  .assimetria_focal.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[form_id].assimetria_focal.direita =
                  e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="checkbox"
              name={`assimetria_focal_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id]
                  .assimetria_focal.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[
                  form_id
                ].assimetria_focal.esquerda = e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Assimetria difusa</h3>
          </td>
          <td>
            <input
              type="checkbox"
              name={`assimetria_difusa_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id]
                  .assimetria_difusa.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[
                  form_id
                ].assimetria_difusa.direita = e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="checkbox"
              name={`assimetria_difusa_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id]
                  .assimetria_difusa.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[
                  form_id
                ].assimetria_difusa.esquerda = e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Área densa</h3>
          </td>
          <td>
            <input
              type="checkbox"
              name={`area_densa_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id].area_densa
                  .direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[form_id].area_densa.direita =
                  e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="checkbox"
              name={`area_densa_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id].area_densa
                  .esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[form_id].area_densa.esquerda =
                  e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <h3>Distorção focal</h3>
          </td>
          <td>
            <input
              type="checkbox"
              name={`distorcao_focal_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id]
                  .distorcao_focal.direita
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[form_id].distorcao_focal.direita =
                  e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
          <td>
            <input
              type="checkbox"
              name={`distorcao_focal_${form_id}`}
              checked={
                mamografiaAnswers.IndicacaoClinicaSection[form_id]
                  .distorcao_focal.esquerda
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection[form_id].distorcao_focal.esquerda =
                  e.target.checked;
                setMamografiaAnswers(temp);
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function RenderAchadosNoExameClinico({
  lado_da_mama,
  mamografiaAnswers,
  setMamografiaAnswers,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #cecece",
        padding: "0.6rem",
      }}
    >
      <h3>Mama {lado_da_mama.toUpperCase()}</h3>
      <div style={{ display: "flex", gap: "0.6rem", alignItems: "flex-end" }}>
        <Checkbox
          label="Lesão papilar"
          name={`lesao_papilar_mama_${lado_da_mama}`}
          checked={
            mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
              `mama_${lado_da_mama}`
            ].lesao_papilar
          }
          onChange={(e) => {
            let temp = { ...mamografiaAnswers };
            temp.IndicacaoClinicaSection.achados_exame_clinico[
              `mama_${lado_da_mama}`
            ].lesao_papilar = e;
            setMamografiaAnswers(temp);
          }}
        />
        <QuestionBody>
          <h5>Descarga papilar</h5>
          <div style={{ display: "flex", gap: "0.6rem" }}>
            <RadioButton
              label="Cristalina"
              value="Cristalina"
              name="descarga_papilar"
              checked={
                mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                  `mama_${lado_da_mama}`
                ].descarga_papilar === "Cristalina"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection.achados_exame_clinico[
                  `mama_${lado_da_mama}`
                ].descarga_papilar = e;
                setMamografiaAnswers(temp);
              }}
            />
            <RadioButton
              label="Hemorrágica"
              value="Hemorrágica"
              name="descarga_papilar"
              checked={
                mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                  `mama_${lado_da_mama}`
                ].descarga_papilar === "Hemorrágica"
              }
              onChange={(e) => {
                let temp = { ...mamografiaAnswers };
                temp.IndicacaoClinicaSection.achados_exame_clinico[
                  `mama_${lado_da_mama}`
                ].descarga_papilar = e;
                setMamografiaAnswers(temp);
              }}
            />
          </div>
        </QuestionBody>
      </div>

      <QuestionBody>
        <h5>Nódulo localização</h5>
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <Checkbox
            label="QSL"
            value="QSL"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.qsl
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.qsl = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="QIL"
            value="QIL"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.qil
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.qil = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="QSM"
            value="QSM"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.qsm
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.qsm = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="QIM"
            value="QIM"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.qim
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.qim = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="UQLat"
            value="UQLat"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.uqlat
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.uqlat = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <Checkbox
            label="UQsup"
            value="UQsup"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.uqsup
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.uqsup = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="UQmed"
            value="UQmed"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.uqmed
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.uqmed = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="UQinf"
            value="UQinf"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.uqinf
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.uqinf = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="RRA"
            value="RRA"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.rra
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.rra = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="PA"
            value="PA"
            name={`nodulo_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.pa
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].nodulo_localizacao.pa = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
      </QuestionBody>
      <QuestionBody>
        <h5>Espessamento localização</h5>
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <Checkbox
            label="QSL"
            value="QSL"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.qsl
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.qsl = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="QIL"
            value="QIL"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.qil
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.qil = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="QSM"
            value="QSM"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.qsm
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.qsm = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="QIM"
            value="QIM"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.qim
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.qim = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="UQLateral"
            value="UQLat"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.uqlat
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.uqlat = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <Checkbox
            label="UQsuperior"
            value="UQsup"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.uqsup
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.uqsup = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="UQmed"
            value="UQmed"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.uqmed
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.uqmed = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="UQinf"
            value="UQinf"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.uqinf
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.uqinf = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="RRA"
            value="RRA"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.rra
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.rra = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="PA"
            value="PA"
            name={`espessamento_localizacao_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.pa
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].espessamento_localizacao.pa = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
      </QuestionBody>

      <QuestionBody>
        <h5>Linfoadenopatia palpável</h5>
        <div style={{ display: "flex", gap: "0.6rem" }}>
          <Checkbox
            label="Axilar"
            value="Axilar"
            name={`linfoadenopatia_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].linfoadenopatia_palpavel.axilar
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].linfoadenopatia_palpavel.axilar = e;
              setMamografiaAnswers(temp);
            }}
          />
          <Checkbox
            label="Supraclavicular"
            value="Supraclavicular"
            name={`linfoadenopatia_mama_${lado_da_mama}`}
            checked={
              mamografiaAnswers.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].linfoadenopatia_palpavel.supraclavicular
            }
            onChange={(e) => {
              let temp = { ...mamografiaAnswers };
              temp.IndicacaoClinicaSection.achados_exame_clinico[
                `mama_${lado_da_mama}`
              ].linfoadenopatia_palpavel.supraclavicular = e;
              setMamografiaAnswers(temp);
            }}
          />
        </div>
      </QuestionBody>
    </div>
  );
}

function QuestionBody({ children }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontSize: "1.4rem",
        gap: "0.4rem",
      }}
    >
      {children}
    </div>
  );
}

function QuestionHeader({ index, text }) {
  return (
    <span
      style={{
        fontWeight: "bold",
      }}
    >
      <span style={{ opacity: 0.4 }}>{index}.</span> {text}
    </span>
  );
}
