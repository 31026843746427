import style from "./style.module.css";

export default function Checkbox({
  label,
  value,
  name,
  checked = false,
  onChange = () => {},
  onClick = () => {},
}) {
  return (
    <label
      className={style.container}
      style={{
        fontWeight: checked ? "bold" : "normal",
      }}
      onClick={onClick}
    >
      {label}
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span className={style.checkmark}></span>
    </label>
  );
}
