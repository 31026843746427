import { get, post } from "utilities/requests"

export const getCheckinSchedule = async (value, type) => {
  return await get(`checkin/adm/agendamentos?key=${type}&${type}=${value}`)
}

export const postLogin = async (body) => {
  return await post(`configs/user/loginPainel`,body)
}


export const generateCheckin = async (body) => {
  // 'http://10.0.100.1:3001/api/'
  return await post(`checkin/gerar-atendimento`,body)
}

export const getFile = async (id) => {
  return await get(`file/${id}`)
}