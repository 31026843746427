import { Check } from "@phosphor-icons/react";
import CustomButton from "components/CustomButton";
import RadioButton from "components/RadioButton";
import { useState } from "react";
import { generateCheckin, getCheckinSchedule } from "services/CheckinService";

export default function SummaryPapanicolauSection({
  questions,
  setSectionIndex,
  selected_user,
  setTicketFile,
  setOthersAppoitments,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  console.log(selected_user);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        gap: "4vh",
        flexDirection: "column",
        padding: "3vw",
        overflowY: "scroll",
        paddingTop: 0,
      }}
    >
      {/* summary questions */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2vh",
        }}
      >
        {questions.map((question, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              fontSize: "1.4rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              <span style={{ opacity: 0.4 }}>{index + 1}.</span>{" "}
              {question.question}
            </span>

            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <RadioButton
                label={question.selected_option || "Nenhuma opção selecionada"}
                value={question.selected_option || "Nenhuma opção selecionada"}
                checked={true}
              />

              {question.selected_exam_year !== undefined && (
                <span
                  style={{
                    backgroundColor: "#f8f8f8",
                    border: "1px solid #ececec",
                    borderRadius: "10rem",
                    padding: "0.6rem 1.2rem",
                    fontSize: "1.1rem",
                  }}
                >
                  Ano do exame: <b>{question.selected_exam_year ?? "-"}</b>
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div style={{ fontSize: 13, marginTop: 10 }}>
        {/* <a href="#">Ou registre-se clicando aqui</a> */}
        {message && (
          <p style={{ textAlign: "center", color: "red" }}>{message}</p>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {isLoading == false && (
          <CustomButton
            text={"Corrigir"}
            icon={<Check size={26} />}
            onClick={() => {
              setSectionIndex(2);
            }}
          />
        )}
        <CustomButton
          text={"Confirmar"}
          icon={<Check size={26} />}
          isLoading={isLoading}
          onClick={async () => {
            setMessage("");
            setIsLoading(true);
            let response = await generateCheckin(
              JSON.stringify({
                nr_seq_agenda: selected_user.nr_seq_agenda,
                cd_tipo_agenda: selected_user.cd_tipo_agenda,
              })
            );
            if (!response.ticketFile) {
              setIsLoading(false);
              setMessage(
                "Algo deu errado ao gerar e etiqueta. Tente novamente"
              );
              return;
            }
            const othersAppoitments = await getCheckinSchedule(
              selected_user.nr_cpf,
              "cpf"
            );
            if (othersAppoitments.consultas == undefined) {
              setIsLoading(false);
              setMessage(
                "Algo deu errado ao gerar e etiqueta. Tente novamente"
              );
              return;
            }
            setIsLoading(false);
            setTicketFile(response);
            setOthersAppoitments(othersAppoitments);

            setSectionIndex(4);
          }}
        />
      </div>
    </div>
  );
}
