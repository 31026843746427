import GetUserSection from "./sections/GetUser";
import CustomizedSteppers from "./Stepper";
import { useEffect, useState } from "react";
import SelectUserSection from "./sections/SelectUser";
import CustomButton from "components/CustomButton";
import { ArrowLeft } from "@phosphor-icons/react";
import QuestionsPapanicolauSection from "./sections/QuestionsPapanicolau";
import GenerateTag from "./sections/GenerateTag";
import SwiperCarousel from "./SwiperCarousel";
import ha_logo from "assets/static/ha_logo.png";
import SummaryPapanicolauSection from "./sections/QuestionsSummary/SummaryPapanicolau";
import { Power } from "@phosphor-icons/react";
import QuestionsMamografiaSection from "./sections/QuestionsMamografia";
import mamografia from "assets/static/mamografia.jpg";
import papanicolau from "assets/static/papanicolau.jpg";
import SummaryMamografiaSection from "./sections/QuestionsSummary/SummaryMamografia";

export default function GerarEtiquetaPage() {
  const [othersAppoitments, setOthersAppoitments] = useState([false]);
  const [selectedExamType, setSelectedExamType] = useState(null);

  const clearSections = () => {
    setQuestions([
      {
        id: 1,
        question: "Motivo do exame",
        options: ["Rastreamento", "Repetição", "Seguimento"],
        selected_option: null,
      },
      {
        id: 2,
        question: "Fez o exame preventivo alguma vez? (Papanicolau)",
        options: ["Sim", "Não", "Não sei", "Sem informação da ficha"],
        selected_option: null,
        selected_exam_year: null,
      },
      {
        id: 3,
        question: "Usa DIU?",
        options: ["Sim", "Não", "Não sei"],
        selected_option: null,
      },
      {
        id: 4,
        question: "Está grávida?",
        options: ["Sim", "Não", "Não sei"],
        selected_option: null,
      },
      {
        id: 5,
        question: "Usa anticoncepcional?",
        options: ["Sim", "Não", "Não sei"],
        selected_option: null,
      },
    ]);
    setData({ consultas: [], exames: [] });
    setSelectedUser(null);
    setTicketFile({});
    setQueryType("cpf");
    setSectionIndex(0);
  };

  const [section_index, setSectionIndex] = useState(0);
  const [query_type, setQueryType] = useState("cpf");
  const [user, setUser] = useState({});

  // const [selected_user, setSelectedUser] = useState(null);
  const [selected_user, setSelectedUser] = useState({
    cd_paciente: 0,
    nm_paciente: "",
    cd_tipo_agenda: 0,
    dt_agenda: "",
  });
  const [ticketFile, setTicketFile] = useState({});

  const [data, setData] = useState({ consultas: [], exames: [] });
  const [questions, setQuestions] = useState([
    {
      id: 1,
      question: "Motivo do exame",
      options: ["Rastreamento", "Repetição", "Seguimento"],
      selected_option: null,
    },
    {
      id: 2,
      question: "Fez o exame preventivo alguma vez? (Papanicolau)",
      options: ["Sim", "Não", "Não sei", "Sem informação da ficha"],
      selected_option: null,
      selected_exam_year: null,
    },
    {
      id: 3,
      question: "Usa DIU?",
      options: ["Sim", "Não", "Não sei"],
      selected_option: null,
    },
    {
      id: 4,
      question: "Está grávida?",
      options: ["Sim", "Não", "Não sei"],
      selected_option: null,
    },
    {
      id: 5,
      question: "Usa anticoncepcional?",
      options: ["Sim", "Não", "Não sei"],
      selected_option: null,
    },
  ]);
  const [mamografiaAnswers, setMamografiaAnswers] = useState({
    DadosAnamneseSection: {
      nodulo_caroco_mama: {
        mama_direita: false,
        mama_esquerda: false,
        nao: false,
      },
      risco_elevado_cancer_mama: null,
      mamas_examinadas_profissional_saude: null,
      fez_mamografia_alguma_vez: {
        answer: null,
        year: null,
      },
    },
    IndicacaoClinicaSection: {
      mama_diagnostica: null,
      achados_exame_clinico: {
        show: false,
        mama_direita: {
          lesao_papilar: false,
          descarga_papilar: null,
          nodulo_localizacao: {
            qsl: false,
            qil: false,
            qsm: false,
            qim: false,
            uqlat: false,
            uqsup: false,
            uqmed: false,
            uqinf: false,
            rra: false,
            pa: false,
          },
          espessamento_localizacao: {
            qsl: false,
            qil: false,
            qsm: false,
            qim: false,
            uqlat: false,
            uqsup: false,
            uqmed: false,
            uqinf: false,
            rra: false,
            pa: false,
          },
          linfoadenopatia_palpavel: {
            axilar: false,
            supraclavicular: false,
          },
        },
        mama_esquerda: {
          lesao_papilar: false,
          descarga_papilar: null,
          nodulo_localizacao: {
            qsl: false,
            qil: false,
            qsm: false,
            qim: false,
            uqlat: false,
            uqsup: false,
            uqmed: false,
            uqinf: false,
            rra: false,
            pa: false,
          },
          espessamento_localizacao: {
            qsl: false,
            qil: false,
            qsm: false,
            qim: false,
            uqlat: false,
            uqsup: false,
            uqmed: false,
            uqinf: false,
            rra: false,
            pa: false,
          },
          linfoadenopatia_palpavel: {
            axilar: false,
            supraclavicular: false,
          },
        },
      },
      controle_radiologico_categoria_3: {
        show: false,
        nodulo: {
          direita: false,
          esquerda: false,
        },
        microcalcificacoes: {
          direita: false,
          esquerda: false,
        },
        assimetria_focal: {
          direita: false,
          esquerda: false,
        },
        assimetria_difusa: {
          direita: false,
          esquerda: false,
        },
        area_densa: {
          direita: false,
          esquerda: false,
        },
        distorcao_focal: {
          direita: false,
          esquerda: false,
        },
      },
      lesao_diagnostico_cancer: {
        show: false,
        nodulo: {
          direita: false,
          esquerda: false,
        },
        microcalcificacoes: {
          direita: false,
          esquerda: false,
        },
        assimetria_focal: {
          direita: false,
          esquerda: false,
        },
        assimetria_difusa: {
          direita: false,
          esquerda: false,
        },
        area_densa: {
          direita: false,
          esquerda: false,
        },
        distorcao_focal: {
          direita: false,
          esquerda: false,
        },
      },
      avaliacao_resposta_qt_neo_adjuvante: false,
      mamografia_rastreamento: {
        show: false,
        data_solicitacao: null,
      },
    },
    DadosAnamneseUnidadeRadiologicaSection: {
      historico_menstrual: {
        ultima_menstruacao: {
          data: null,
          nao_lembra: false,
        },
        menopausa: {
          anos: null,
          nao_lembra: false,
        },
        nunca_menstruou: false,
      },
      usa_hormonio_remedio_tratar_menopausa: null,
      esta_gravida: null,
      fez_radioterapia: {
        answer: null,
        ano: null,
      },
      fez_cirurgia_de_mama: {
        tumorectomia: {
          direita: null,
          esquerda: null,
        },
        segmentectomia: {
          direita: null,
          esquerda: null,
        },
        dutectomia: {
          direita: null,
          esquerda: null,
        },
        mastectomia: {
          direita: null,
          esquerda: null,
        },
        mastectomia_poup_pele: {
          direita: null,
          esquerda: null,
        },
        linfadenectomia_axilar: {
          direita: null,
          esquerda: null,
        },
        biopsia_linfonodo_sentinela: {
          direita: null,
          esquerda: null,
        },
        reconstrucao_mamaria: {
          direita: null,
          esquerda: null,
        },
        plastica_redutora: {
          direita: null,
          esquerda: null,
        },
        plastica_com_implantes: {
          direita: null,
          esquerda: null,
        },
      },
    },
  });

  return (
    <>
      {/* exam type select */}
      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: selectedExamType === null ? 0 : "-100%",
          left: 0,
          opacity: selectedExamType === null ? 1 : 0,
          width: "100%",
          height: "100%",
          zIndex: 100,
          backgroundColor: "rgba(0,0,0,0.7)",
          backdropFilter: "blur(5px)",
          transition: "top 1s ease, opacity 0.3s ease",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <h1>Selecione o tipo de exame</h1>

          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedExamType("mamografia");
              }}
            >
              <img
                src={mamografia}
                alt="Mamografia"
                style={{
                  width: "20rem",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
              />
              <h2>Mamografia</h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedExamType("papanicolau");
              }}
            >
              <img
                src={papanicolau}
                alt="Papanicolau"
                style={{
                  width: "20rem",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
              />
              <h2>Papanicolau</h2>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          height: "100vh",
          // width: "100vw",
        }}
      >
        {/* <div
          className="hidethis"
          style={{
            display: hide_carousel ? "none" : "flex",
            width: "40%",
            height: "100vh",
            position: "fixed",
          }}
        >
          <SwiperCarousel />
          <img
            src={ha_logo}
            alt="Hospital de Amor Logotipo"
            style={{
              position: "absolute",
              bottom: "1rem",
              left: "50%",
              transform: "translate(-50%, 0%)",
              width: "18rem",
              zIndex: 2,
            }}
          />
        </div> */}
        <div
          className="margin-none"
          style={{
            // display: "flex",
            flexDirection: "column",
            width: "100%",
            // height: "100vh",
            marginLeft: "0%",
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "1vh",
            // padding:10
          }}
        >
          <div
            className="hidethis"
            style={{ display: "flex", justifyContent: "end", margin: 10 }}
          >
            <div>
              <button
                className="btn_ btn-outline-primary_"
                style={{
                  fontSize: 14,
                  borderRadius: 18,
                  cursor: "pointer",
                  marginRight: 10,
                }}
                onClick={(e) => {
                  setSectionIndex(2);
                }}
              >
                Perguntas
              </button>
              <button
                className="btn_ btn-outline-danger_"
                style={{ fontSize: 14, borderRadius: 18, cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                <Power /> Sair
              </button>
            </div>
          </div>

          <p
            className="hidethis"
            style={{ fontWeight: "normal", fontSize: 14, textAlign: "center" }}
          >
            <strong>Olá, {user?.DS_USUARIO}</strong>. Você pode navegar pelas
            seções. Basta clicar.
          </p>
          <CustomizedSteppers
            {...{ section_index, setSectionIndex, selectedExamType }}
          />
          <div
            className="overflow_hidden"
            style={{
              alignItems: "center",
              justifyContent: "center",
              overflowX: "scroll",
            }}
          >
            {section_index === 0 && (
              <GetUserSection
                {...{
                  query_type,
                  setQueryType,
                  setSectionIndex,
                  setData,
                }}
              />
            )}
            {section_index === 1 && (
              <div
                className="hidethis"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // height: "100vh",
                  // overflow: "hidden",
                  width: "100%",
                  gap: "0.6rem",
                  padding: "1rem 0",
                }}
              >
                <SelectUserSection
                  {...{
                    data,
                    selected_user,
                    setSelectedUser,
                    setSectionIndex,
                  }}
                />

                <div
                  style={{
                    paddingLeft: "3vw",
                  }}
                >
                  <CustomButton
                    text="Voltar"
                    icon={<ArrowLeft size={26} />}
                    onClick={() => {
                      setSectionIndex(0);
                    }}
                  />
                </div>
              </div>
            )}
            {section_index === 2 && (
              <div>
                <p
                  style={{ textAlign: "center", fontSize: 14, marginBottom: 5 }}
                >
                  <b>Paciente:</b> {selected_user.nm_paciente}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  <b>Tipo:</b>{" "}
                  {selected_user.cd_tipo_agenda == 3 ? "Consulta" : "Exame"} -{" "}
                  {selected_user.dt_agenda}
                </p>

                {selectedExamType === "papanicolau" ? (
                  <QuestionsPapanicolauSection
                    {...{ questions, setQuestions, setSectionIndex }}
                  />
                ) : selectedExamType === "mamografia" ? (
                  <QuestionsMamografiaSection
                    {...{
                      mamografiaAnswers,
                      setMamografiaAnswers,
                      setSectionIndex,
                    }}
                  />
                ) : null}
              </div>
            )}
            {section_index === 3 && (
              <div>
                <p
                  style={{ textAlign: "center", fontSize: 14, marginBottom: 5 }}
                >
                  <b>Paciente:</b> {selected_user.nm_paciente}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  <b>Tipo:</b>{" "}
                  {selected_user.cd_tipo_agenda == 3 ? "Consulta" : "Exame"} -{" "}
                  {selected_user.dt_agenda}
                </p>

                {selectedExamType === "papanicolau" && (
                  <SummaryPapanicolauSection
                    setOthersAppoitments={setOthersAppoitments}
                    setTicketFile={setTicketFile}
                    selected_user={selected_user}
                    {...{ questions, setSectionIndex }}
                  />
                )}
              </div>
            )}
            {section_index === 4 && (
              <div>
                <p
                  className="hidethis"
                  style={{ textAlign: "center", fontSize: 14, marginBottom: 5 }}
                >
                  <b>Paciente:</b> {selected_user.nm_paciente}
                </p>
                <p
                  className="hidethis"
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  <b>Tipo:</b>{" "}
                  {selected_user.cd_tipo_agenda == 3 ? "Consulta" : "Exame"} -{" "}
                  {selected_user.dt_agenda}
                </p>
                <GenerateTag
                  othersAppoitments={othersAppoitments}
                  setSectionIndex={setSectionIndex}
                  setSelectedUser={setSelectedUser}
                  selected_user={selected_user}
                  clearSections={clearSections}
                  ticketFile={ticketFile}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
