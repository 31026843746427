import { useState } from "react";
import RadioButton from "components/RadioButton";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import CustomButton from "components/CustomButton";
import { toast } from "react-toastify";
import swal from "sweetalert";
import ReactInputMask from "react-input-mask";
import CustomInput from "components/CustomInput";
import moment from "moment";

export default function QuestionsPapanicolauSection({
  questions,
  setQuestions,
  setSectionIndex,
}) {
  const [selected_question_index, setSelectedQuestionIndex] = useState(0);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        gap: "4vh",
        flexDirection: "column",
        padding: "3vw",
        overflowY: "scroll",
        paddingTop: 0,
      }}
    >
      {/* array from 1 to 5 */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {[...Array(5).keys()].map((index) => (
          <span
            style={{
              cursor: "pointer",
              backgroundColor:
                index === selected_question_index ? "#647ccc" : "",
              border: "1px solid #c9c9c9",
              padding: "0.4rem 1rem",
              borderRadius: "10rem",
              color: index === selected_question_index ? "white" : "#aaa",
              transition: "all 0.3s",
            }}
            onClick={() => {
              setSelectedQuestionIndex(index);
            }}
          >
            <b>Questão {index + 1}</b>
          </span>
        ))}
      </div>

      {/* render selected question from array */}

      {questions[selected_question_index] && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2vh",
            marginTop: "2vh",
            fontSize: "1.4rem",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            <span style={{ opacity: 0.4 }}>{selected_question_index + 1}.</span>{" "}
            {questions[selected_question_index].question}
          </span>

          {/* render radios */}
          {questions[selected_question_index].options.map((option) => (
            <RadioButton
              label={option}
              value={option}
              checked={
                questions[selected_question_index].selected_option === option
              }
              onChange={() => {
                const new_questions = [...questions];
                new_questions[selected_question_index].selected_option = option;
                setQuestions(new_questions);
                setTimeout(() => {
                  if (selected_question_index === questions.length - 1) {
                    setSectionIndex(3);
                    return;
                  } else {
                    if (
                      selected_question_index === 1 &&
                      questions[1].selected_option === "Sim"
                    ) {
                      return;
                      // return toast.info(
                      //   "Você precisa inserir o ano do exame.",
                      //   {
                      //     position: "top-right",
                      //     autoClose: 3000,
                      //     hideProgressBar: false,
                      //     closeOnClick: true,
                      //     pauseOnHover: true,
                      //     draggable: true,
                      //     progress: undefined,
                      //   }
                      // );
                    }
                    setSelectedQuestionIndex(selected_question_index + 1);
                  }
                }, 600);
              }}
            />
          ))}

          {selected_question_index === 1 &&
            questions[1].selected_option === "Sim" && (
              <>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Ano do exame
                </span>
                <ReactInputMask
                  mask={"9999"}
                  defaultValue={questions[1].selected_exam_year}
                  onChange={(e) => {
                    const new_questions = [...questions];
                    new_questions[1].selected_exam_year = e;
                    setQuestions(new_questions);
                  }}
                  disabled={false}
                  maskChar=""
                >
                  {() => (
                    <CustomInput
                      type="text"
                      placeholder="Insira a data"
                      inputStyle={{
                        maxWidth: "16rem",
                      }}
                    />
                  )}
                </ReactInputMask>
              </>
            )}
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomButton
          text={"Anterior"}
          icon={<ArrowLeft size={26} />}
          disabled={selected_question_index === 0}
          onClick={() => {
            setSelectedQuestionIndex(selected_question_index - 1);
          }}
        />
        <CustomButton
          text={
            selected_question_index === questions.length - 1
              ? "Finalizar"
              : "Próxima"
          }
          icon={<ArrowRight size={26} />}
          onClick={() => {
            if (
              selected_question_index === questions.length - 1 &&
              questions[selected_question_index].selected_option != null
            ) {
              setSectionIndex(3);
              return;
            }
            // check if the question is answered
            if (questions[selected_question_index].selected_option === null) {
              swal({
                title: "Erro!",
                text: "Você precisa responder a pergunta antes de prosseguir.",
                icon: "error",
                button: "Ok",
              });

              return;
            } else if (
              selected_question_index === 1 &&
              questions[1].selected_option === "Sim" &&
              (!questions[1].selected_exam_year ||
                parseInt(questions[1].selected_exam_year) < 1930 ||
                parseInt(questions[1].selected_exam_year) >
                  parseInt(moment().format("YYYY")))
            ) {
              toast.info("Você precisa inserir o ano do exame.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return;
            }
            setSelectedQuestionIndex(selected_question_index + 1);
          }}
        />
      </div>
    </div>
  );
}
